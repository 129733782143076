<template>
  <div class="footer">
    <div style="
      width: 100%;
      height: 5px;
      background-color: var(--c-primary);
    "></div>
    <div class="footer-content">
      <div class="footer-links">
        <a href="#home" @click="update()"><p class="text-button">Hem</p></a>
        <router-link to="/posts"><p class="text-button text-c">Inlägg</p></router-link>
        <a href="#section-app" @click="burgerActive = false; update()"><p class="text-button">Om appen</p></a>
        <a href="#section-about" @click="burgerActive = false; update()"><p class="text-button">Om oss</p></a>
      </div>

      <navbar-logo style="margin: 30px 0"/>

      <p class="text-small text-c">
        © Blomstrande Liv {{currentYear}} | 
        <a href="https://www.devyne.se" class="hoverable">Devyne Software</a> | 
        <router-link to="/privacy" class="hoverable">Integritetspolicy</router-link> | 
        <router-link to="/admin" class="hoverable">Admin</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import NavbarLogo from './svgs/NavbarLogo.vue'
export default {
  components: { NavbarLogo },
  data() {
    return {
      currentYear: 2023
    }
  },
  mounted() {
    let date = new Date();
    this.currentYear = date.getFullYear();
  },
  methods: {
    update() {
      console.log('update')
      window.history.pushState({},"", '/')
    },
  }
}
</script>

<style scoped>

.footer {
  position: relative;
  bottom: 0;
  left: 0;
  /* width: 100vw; */
  height: 240px;
  box-sizing: border-box;

  background-color: var(--c-primary-shadow);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.footer-links {
  display: flex;
  flex-direction: row;
}

.text-button {
  margin: 0 20px;  
}

.footer-content p {
  margin: 0 10px;
}

@media screen and (max-width: 1200px) {
  .text-button {
    margin: 0 10px;  
  }
}

@media screen and (max-width: 800px) {
  /* .footer-links {
    flex-direction: column;
    align-items: center;
  } */
  .text-button {
    margin: 0 5px;
  }
}

</style>