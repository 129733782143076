import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/posts',
    component: () => import('../views/PostsArchive.vue'),
  },
  {
    path: '/posts/:slug',
    component: () => import('../views/Post.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/admin',
    component: () => import('../views/admin/AdminWrapper.vue'),
    meta: {
      hideNav: true
    },
    children: [
      {
        path: '/',
        component: () => import('../views/admin/AdminDashboard.vue'),
        meta: {
          hideNav: true
        }
      },
      {
        path: 'posts',
        component: () => import('../views/admin/AdminPosts.vue'),
        meta: {
          hideNav: true
        }
      },
      {
        path: 'background',
        component: () => import('../views/admin/AdminBackground.vue'),
        meta: {
          hideNav: true
        }
      },
      {
        name: 'PostsWrite',
        path: 'posts/write',
        props: true,
        component: () => import('../views/admin/AdminWritePost.vue'),
        
        meta: {
          hideNav: true
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if(from.path == '/admin/posts/write') {
//     const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
//   if (answer) {
//     next()
//   } else {
//     next(false)
//   }
//   }
//   else next()
// })

export default router
