<template>
  <div class="scroll-indicator-background">
    <svg width="28" height="50" viewBox="0 0 28 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="scroll-indicator">
        <rect id="border" x="2.23524" y="1.47059" width="23.5294" height="47.0588" rx="11.7647" stroke="#fff" stroke-opacity="0.7" stroke-width="1"/>
        <circle id="ball" cx="14.0001" cy="12.5" r="4.41176" fill="#fff" fill-opacity="0.7"/>
      </g>
    </svg>
  </div>

</template>

<script>
export default {

}
</script>

<style scoped>

.scroll-indicator-background {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;

  background-color: #ffffff19;
  backdrop-filter: blur(20px);

  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

#ball {
  animation: scroll-indicator 2s infinite ease;
}

@keyframes scroll-indicator {
  0% {
    transform: translateY(0%);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(50%);
    opacity: 0;
  }
  
}

</style>