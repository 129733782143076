import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getAuth } from "firebase/auth"
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyAX4rkwt5frpg14CO0cZ8q3XXlAPsZxjNo",
  authDomain: "blomstrande-liv.firebaseapp.com",
  projectId: "blomstrande-liv",
  storageBucket: "blomstrande-liv.appspot.com",
  messagingSenderId: "158848795149",
  appId: "1:158848795149:web:211e2528903cc17ecccb44",
  measurementId: "G-TLKDJGWXFG"

}

const app = initializeApp(firebaseConfig)

const analytics = getAnalytics(app)
const performance = getPerformance(app)
const auth = getAuth()
const db = getFirestore()
const storage = getStorage()

export { auth, db, storage, performance, analytics }