<template>
  <div class="nav"  v-click-outside="dismiss">
    <div @click="dismiss"><a href="#home" @click="update()"><navbar-logo class="hoverable" /></a></div>

    <div class="nav-buttons">
      <div class="nav-burger" @click="toggleBurger(!burgerActive)">
        <div class="nav-burger-line-1" :class="{'nav-burger-active': burgerActive}"></div>
        <div class="nav-burger-line-2" :class="{'nav-burger-active': burgerActive}"></div>
        <div class="nav-burger-line-3" :class="{'nav-burger-active': burgerActive}"></div>
      </div>
      <div class="nav-links" :class="{'nav-burger-active': burgerActive}">
        <div @click="dismiss"><a href="#home" @click="update()"><p class="text-button">Hem</p></a></div>
        <div @click="dismiss"><router-link to="/posts" @click="burgerActive = false"><p class="text-button">Inlägg</p></router-link></div>
        <div @click="dismiss"><a href="#section-app" @click="burgerActive = false; update()"><p class="text-button">Om appen</p></a></div>
        <div @click="dismiss"><a href="#section-about" @click="burgerActive = false; update()"><p class="text-button">Om oss</p></a></div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarLogo from './svgs/NavbarLogo.vue'

export default {
  components: {
    NavbarLogo
  },
  data() {
    return {
      burgerActive: false,
    }
  },
  methods: {
    toggleBurger(toggle) {
      this.burgerActive = toggle
    },
    dismiss() {
      if(this.burgerActive) this.toggleBurger(false)
    },
    update() {
      console.log('update')
      window.history.pushState({},"", '/')
    },
  }
}
</script>

<style scoped>

.nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 65px;
  width: 100%;
  box-sizing: border-box;

  /* background-color: #fcfcfc71;
  backdrop-filter: blur(15px); */
  background-color: #6d629ca1;
  backdrop-filter: blur(25px);
  
  padding: 0 var(--horizontal-margin);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.nav-links {
  display: flex;
  flex-direction: row;
  column-gap: 30px;
}

p {
  font-weight: 500;
}

@media screen and (max-width: 1500px) {
  .nav-links {
    column-gap: 15px;
  }
}

@media screen and (max-width: 800px) {
  .nav-links {
    transition: transform 0.5s ease;
    position: absolute;
    /* display: flex; */
    flex-direction: column;
    width: 100vw;
    top: 65px;
    right: 0;
    transform: translateX(calc(100%));

    /* background-color: var(--c-background); */
    background-color: #6d629c5d;
    backdrop-filter: blur(15px);
  }

  .nav-links.nav-burger-active {
    transform: translateX(0);
  }

  .text-button {
    /* color: var(--c-text-dark); */
    margin: 10px var(--horizontal-margin);
  }

  .nav-burger {
    visibility: visible;
    display: flex;
    flex-direction: column;
  }

  .nav-burger-line-1, .nav-burger-line-2, .nav-burger-line-3 {
    transition: all 200ms ease;
    background-color: var(--c-background);
    width: 20px;
    height: 3px;
    margin: 2px 0;
    /* animation: nav-line-1 300ms ease;
    animation-fill-mode: forwards;
    animation-direction: reverse; */
  }
  /* .nav-burger-line-1.nav-burger-active {
    animation: nav-line-1 300ms ease;
    animation-play-state: running;
    animation-fill-mode: forwards;
  }
  .nav-burger-line-1.nav-burger-inactive {
    animation: nav-line-2 300ms ease;
    animation-direction: reverse;
  } */

  .nav-burger-line-1.nav-burger-active { transform: translateY(7px) rotate(135deg);}
  .nav-burger-line-2.nav-burger-active.nav-burger-active { opacity: 0; }
  .nav-burger-line-3.nav-burger-active { transform: translateY(-7px) rotate(-135deg); }

  .nav-burger-enter-1 {
    animation: nav-line-1 300ms ease;
    animation-fill-mode: forwards;
  }
  .nav-burger-leave-1 {
    animation: nav-line-1 300ms ease;
    animation-direction: reverse;
    animation-fill-mode: forwards;
  }


  @keyframes nav-line-1 {
    0% {transform: translateY(0) rotate(0)}
    50% {transform: translateY(5px) rotate(0);}
    100% {transform: translateY(5px) rotate(45deg);}
  }
  @keyframes nav-line-1 {
    0% {transform: translateY(0) rotate(0)}
    50% {transform: translateY(-5px) rotate(0);}
    100% {transform: translateY(-5px) rotate(-45deg);}
  }

  /* .nav-burger-open {
    transform: translateX(0);
  } */
}

</style>