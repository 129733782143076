import Vue from 'vue'
import Vuex from 'vuex'

import { auth, db, storage } from '../firebase/config'

import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth'
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import { addDoc, collection, deleteField, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user: null,
    isAuthReady: false,
    posts: {},
    backgroundImage: '',
    backgroundLastUpdated: '',
    queryResults: []
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
      console.log('User state changed: ', state.user)
    },
    setIsAuthReady(state, payload) {
      state.isAuthReady = payload
      console.log('Auth is now ready')
    },
    setPosts(state, payload) {
      state.posts = payload
    },
    setBackgroundImage(state, { image, lastUpdated }) {
      state.backgroundImage = image
      state.backgroundLastUpdated = lastUpdated

      console.log('Background image updated', state.backgroundImage, state.backgroundLastUpdated)
    },
    setArchiveQuery(state, { query, order }) {
      let results = []

      if(!query) {
        results = Object.values(state.posts)
      } else {
        const searchRegex = new RegExp(query, "gi");
        results = Object.values(state.posts).filter(post => post.plain.match(searchRegex));
      }
      
      results.sort((a, b) => {
        const aDate = new Date(a.time.replace('+', '.'))
        const bDate = new Date(b.time.replace('+', '.'))
        const res = order ? new Date(aDate).getTime() > new Date(bDate).getTime() : new Date(aDate).getTime() < new Date(bDate).getTime()

        return res ? 1 : -1
      })

      state.queryResults = results;
    }
  },
  actions: {
    async signIn(context, { email, password }) {
      console.log('Signing in: ', email)

      const respons = await signInWithEmailAndPassword(auth, email, password)
      if(respons) {
        context.commit('setUser', respons.user)
      } else {
        throw Error('Could not sign in user: ', email)
      }
    },
    async signOut(context) {
      await signOut(auth)
      context.commit('setUser', null)
    },
    async fetchPosts(context) {
      const ref = doc(db, 'blomstrande-liv', 'posts')
      const snapshot = await getDoc(ref)
      const posts = {}

      if(snapshot.exists()) {
        const entries = snapshot.data()
        for(const entry in entries) {
          posts[entry] = entries[entry]
        }
        context.commit('setPosts', posts)
        context.commit('setArchiveQuery', { query: '', order: false })
      } else {
        console.log('Could not read document: ', ref)
      }
    },
    async fetchBackgroundImage(context) {
      const ref = doc(db, 'blomstrande-liv', 'background-image')
      const snapshot = await getDoc(ref)

      if(snapshot.exists()) {
        const data = snapshot.data()
        context.commit('setBackgroundImage', { image: data['url'], lastUpdated: data['last-changed'] })
      } else {
        console.log('Could not read document: ', ref)
      }
    },
    async uploadFile(context, { folder, file, uid }) {
      const fileRef = ref(storage, `${folder}/${uid.replace('.', '+')}-${file.name}`)
      await uploadBytes(fileRef, file)
        .then((r) => console.log('Succeeded to upload file: ', file.name))
        .catch((r) => console.log('Failed to upload file: ', file.name))
      return await getDownloadURL(fileRef)
    },
    async removeFile(context, { url }) {
      const fileRef = ref(storage, url)
      try {
        await deleteObject(fileRef)
        console.log('Successfully removed file!')
      } catch (e) {
        console.log(e.message)
      }
    },
    async publishPost(context, { post, time }) {
      const ref = doc(db, 'blomstrande-liv', 'posts')

      await updateDoc(ref, {
        [time]: post
      })

      context.dispatch('fetchPosts')

      console.log('Succeeded to publish post')
    },
    async updateBackgroundImage(context, { url, lastUpdated }) {
      const ref = doc(db, 'blomstrande-liv', 'background-image')

      await setDoc(ref, {
        'url': url,
        'last-changed': lastUpdated
      })
      
      console.log('Succeeded to update background image')

      context.commit('setBackgroundImage', { image: url, lastUpdated: lastUpdated })
    },
    async deletePost(context, { data }) {
      console.log('DELETING POST: ', data)
      
      const ref = doc(db, 'blomstrande-liv', 'posts')

      //Delete images
      for (const url of data.images) {
        console.log('Removing image, ', url)
        context.dispatch('removeFile', { url })
      }
      if(data.inTextImages) {
        for (const url of data.inTextImages) {
          console.log('Removing image, ', url)
          context.dispatch('removeFile', { url })
        }
      }

      await updateDoc(ref, {
        [data.time]: deleteField()
      })

      context.dispatch('fetchPosts')
    },
  },
  modules: {
  }
})

const unsub = onAuthStateChanged(auth, (user) => {
  // try {
  //   const lastLogin = moment(new Date(+user.reloadUserInfo.lastLoginAt))
  //   const duration = moment.duration(moment().diff(lastLogin))
  //   if (duration.asHours() > 1) user = null
  // } catch (e) {
  //   console.log('Error checking login time: ', e)
  // }

  store.commit('setIsAuthReady', true)
  store.commit('setUser', user)
  store.dispatch('fetchPosts')
  store.dispatch('fetchBackgroundImage')
  unsub()
})

export default store
