<template>
  <div class="home">
    <div style="position: relative;">
      <div class="background-container" ref="home">
        <img 
          class="home-background" 
          :src="$store.state.backgroundImage" 
          
          :style="`transform: translateY(${parallaxOffset}px)`"
        />
      </div>
      <section class="banner">
        <div class="subtile">
          <h3 style="font-size: 22px; font-family: 'Berkshire Swash', serif;">“Lycka är att blomstra som människa” - Aristoteles</h3>
        </div>
      </section>
      
      <!-- <scroll-indicator class="scroll-indicator" :class="{'scroll-indicator-hidden': !showScrollIndicator}" /> -->
    </div>
    
    <main class="section">
      <h2 class="text--dark" style="margin-bottom: 20px;">Välkommen till Blomstrande liv!</h2>

      <p class="text--dark" style="margin: 20px 0">
        Aristoteles beskrev lycka, eudaimonia, som att blomstra som människa. Med detta menade han ett varaktigt tillstånd av välgång och välbefinnande till skillnad från den mer flyktiga och kortvariga känslan av njutning, som många strävar efter idag. 
      </p>

      <p class="text--dark" style="margin: 20px 0">
        Njutningen upplevs ofta på egen hand och har ett pris, lyckan delas med andra och är gratis. Njutningen drivs i hjärnan av dopamin och kan leda till beroende, lyckan styrs av serotonin som ger ett lugn och inre välbefinnande. 
      </p>

      <p class="text--dark" style="margin: 20px 0">
        Njutningen säger "det här känns bra, jag vill ha mer" där lyckan säger "det här känns bra, jag behöver inget mer". 
      </p>

      <p class="text--dark">
        Vi möter alla svårigheter i livet som kan såra oss på olika sätt och hindra oss från att leva våra liv fullt ut. Men det finns hopp! Vi kommer dela med oss av livberättelser och erfarenheter från människor som på olika sätt fått uppleva Guds kärlek och blivit helade från de sår de burit på och börjat blomstra i sina liv i mötet med Gud. Först där kan vi uppleva den djupare lyckan, glädjen och tillfredsställelsen. Ett blomstrande liv är ett spännande liv! Vår förhoppning är att dessa historier ska få bli till inspiration för dig att också kunna blomstra som människa.
      </p>

      <div class="top-posts" style="margin-top: 50px">
        <div v-for="post in postsToDisplay" :key="post.slug">
          <post-archive :large="false" :post="post" />
        </div>
      </div>
      <div class="top-posts-header">
        <!-- <h2 class="text--dark" style="font-weight: bold; flex: 1">Senaste inläggen</h2> -->
        <router-link to="/posts">
          <button class="button">Fler inlägg</button>
        </router-link>
      </div>
    </main>



    <div style="position: relative" ref="sectionApp">
      <ParallaxJS :img="img1" titleText="Blomstra i äktenskapet" />
    </div>

    <section class="section section-app">
      <div class="section-app-text">
        <div>
          <h2 class="text--dark" style="margin-bottom: 20px;">Blomstra i äktenskapet</h2>
          <p class="text--dark">
            Under många år har vi särskilt varit intresserade av att kunna stärka äktenskap och relationer som knakat i fogarna. Vi har hållit i en del äktenskapskurser/-seminarier och även gått en äktenskapsrådgivarutbildning via Trygga familjer (<a class="hoverable text--primary" href="https://www.tryggafamiljer.nu/">www.tryggafamiljer.nu</a>) och finns tillgängliga via deras nätverk för stödsamtal till par i behov av samtal. 
          </p>
          <p class="text--dark" style="margin: 20px 0">
            Vi har även tagit fram en mobilapp för android och iOS som finns att ladda ner helt utan kostnad (och utan reklam). Tanken är att den ska kunna vara till hjälp för de par som har problem i sin relation, men även för dem som kanske inte har problem men som vill fördjupa relationen och se den blomstra.
          </p>
          <p class="text--dark" style="margin: 20px 0">
            Appen heter Blomstra och finns på Google Play och App Store.
          </p>
          <p class="text--dark" style="margin: 20px 0">
            Appen innehåller 5 olika övningar. Hur man gör beskrivs i appen under respektive övning. De första tre är relativt snabba och enkla och är tänkta att kunna göras tillsammans dagligen för att succesivt fördjupa relationen. Den fjärde innehåller roliga och romantiska utmaningar att göra för sin partner och den sista innehåller mer djupgående frågor som är lämpliga att ta i samband med date-nights.
          </p>
          <p class="text--dark">
            Om intresse finns att hålla äktenskapskurser eller seminarier i er församling eller organisation så går det bra att kontakta oss via <a class="hoverable text--primary" href="mailto:info.blomstrandeliv@gmail.com">info.blomstrandeliv@gmail.com</a>. 
          </p>
        </div>
        <div class="app-section-download">
          <a href="https://apps.apple.com/us/app/blomstra/id6443509315">
            <img :src="imgDownloadAppStore">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.devyne.blomstra&hl=se&gl=SE">
            <img :src="imgDownloadGooglePlay">
          </a>
        </div>
      </div>
      <div class="section-app-img">
        <img :src="imgAppDisplay" />
      </div>
    </section>
  
    <div style="position: relative" ref="sectionAbout">
      <ParallaxJS :img="img2" titleText="Om oss" />
    </div>

    <section class="section section-about">
      <div class="section-about-paragraph">
        <div class="section-about-img" style="flex: 1;">
          <img :src="imgPontus" />
        </div>
        <div class="section-about-paragraph-text">
          <h2 class="text--dark" style="margin-bottom: 20px;">Pontus och Tina</h2>
          <p class="text--dark">Vi som har skapat denna sida heter Pontus och Tina Runeke. Vi har varit gifta i över 30 år och har 3 barn och 2 barnbarn. Vi är kristna och medlemmar i en församling i Helsingborg, där vi bor. Vår tro och vår relation med Gud är det viktigaste i våra liv och utgör grunden för det vi delar med oss av här.</p>
          <p class="text--dark" style="margin-top: 20px">Pontus är utbildad landskapsarkitekt och driver idag främst en konsultverksamhet i eget företag. Han har tidigare haft chefsposition och varit personalansvarig i både offentlig och privat regi och har genom det erfarenhet av samtal med medarbetare. Idag håller han även webbinarier på uppdrag av olika utbildningsorganisationer inom bland annat juridik, avtal och ledarskap.</p>
          <p class="text--dark" style="margin-top: 20px">Tina är läkare, specialist i allmänmedicin, och jobbar utöver arbetet på en vårdcentral även med att författa digitala kunskapsstöd för primärvården i Region Skåne.</p>
        </div>
      </div>
      <div class="section-about-paragraph section-about-paragraph-bottom" style="margin-top: 60px">
        <div class="section-about-img" style="flex: 1;">
          <img :src="imgTina" style="right: 0;" />
        </div>
        <div class="section-about-paragraph-text">
          <h2 class="text--dark" style="margin-bottom: 20px;">Vår vision</h2>
          <p class="text--dark">Att dela livsberättelser och upplevelser med varandra är en av de saker som kan föra oss människor närmare varandra. Att få höra hur andra blivit hjälpta kan stärka oss och ge nytt hopp i en svår situation. Vi hoppas att dessa livsberättelser och olika människors livsvisdom kommer kunna bli till en inspirationskälla för dig, precis som den varit för oss. Gud är en god Gud och han vill möta alla som sträcker sig efter honom.</p>
          <p class="text--dark" style="margin-top: 20px">Vi gör detta helt ideellt och har ingen ekonomisk ersättning eller sponsring från något håll. Vår önskan, och anledningen till att vi gör detta, är att fler ska upptäcka den djupaste meningen med livet och därmed kunna växa och blomstra som människor.</p>
          <p class="text--dark" style="margin-top: 20px">Hoppas att du vill följa med oss!</p>
        </div>
      </div>
    </section>

    <div style="position: relative">
      <ParallaxJS :img="$store.state.backgroundImage" titleText="Kontakt" />
    </div>

    <section class="section section-contact">
      <div class="section-contact-text">
        <div>
          <p class="text--dark">Om du har frågor eller vill veta mer är du välkommen att mejla oss på <a class="hoverable text--primary" href="mailto:info.blomstrandeliv@gmail.com">info.blomstrandeliv@gmail.com</a>. Har du blivit berörd av någon berättelse blir vi också glada om du vill dela med dig av det till oss. </p>
          <p class="text--dark" style="margin-top: 20px">Om ni som par är intresserade av stödsamtal i er relation går det bra att kontakta oss, eller att kontakta Trygga familjer, <a class="hoverable text--primary" href="https://www.tryggafamiljer.nu/">www.tryggafamiljer.nu</a>.</p>
          <!-- <p class="text--dark" style="margin-top: 20px">Följ oss gärna även på Facebook och Instagram för att få uppdatering när nya inlägg publiceras. Det går även bra att mejla oss för att få nyhetsbrev, om du föredrar det.</p> -->
        </div>
        <div class="section-contact-socials">
          <!-- <a href="" class="hoverable">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="facebook">
                <path id="facebook_2" fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM16 8C20.4 8 24 11.6 24 16C24 20 21.1 23.4 17.1 24V18.3H19L19.4 16H17.2V14.5C17.2 13.9 17.5 13.3 18.5 13.3H19.5V11.3C19.5 11.3 18.6 11.1 17.7 11.1C15.9 11.1 14.7 12.2 14.7 14.2V16H12.7V18.3H14.7V23.9C10.9 23.3 8 20 8 16C8 11.6 11.6 8 16 8Z" fill="#584E7E"/>
              </g>
            </svg>
            <p class="text--primary" style="margin-left: 10px">Facebook</p>
          </a> -->
          <a href="https://www.instagram.com/blomstrande.liv/" class="hoverable">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="instagram">
                <g id="instagram_2">
                  <path
                    d="M16 18.8C14.5 18.8 13.2 17.6 13.2 16C13.2 14.5 14.4 13.2 16 13.2C17.5 13.2 18.8 14.4 18.8 16C18.8 17.5 17.5 18.8 16 18.8Z"
                    fill="#584E7E" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M19.4 9.2H12.6C11.8 9.3 11.4 9.4 11.1 9.5C10.7 9.6 10.4 9.8 10.1 10.1C9.86261 10.3374 9.75045 10.5748 9.61489 10.8617C9.57916 10.9373 9.5417 11.0166 9.5 11.1C9.48453 11.1464 9.46667 11.1952 9.44752 11.2475C9.34291 11.5333 9.2 11.9238 9.2 12.6V19.4C9.3 20.2 9.4 20.6 9.5 20.9C9.6 21.3 9.8 21.6 10.1 21.9C10.3374 22.1374 10.5748 22.2495 10.8617 22.3851C10.9374 22.4209 11.0165 22.4583 11.1 22.5C11.1464 22.5155 11.1952 22.5333 11.2475 22.5525C11.5333 22.6571 11.9238 22.8 12.6 22.8H19.4C20.2 22.7 20.6 22.6 20.9 22.5C21.3 22.4 21.6 22.2 21.9 21.9C22.1374 21.6626 22.2495 21.4252 22.3851 21.1383C22.4209 21.0626 22.4583 20.9835 22.5 20.9C22.5155 20.8536 22.5333 20.8048 22.5525 20.7525C22.6571 20.4667 22.8 20.0762 22.8 19.4V12.6C22.7 11.8 22.6 11.4 22.5 11.1C22.4 10.7 22.2 10.4 21.9 10.1C21.6626 9.86261 21.4252 9.75045 21.1383 9.61488C21.0627 9.57918 20.9833 9.54167 20.9 9.5C20.8536 9.48453 20.8048 9.46666 20.7525 9.44752C20.4667 9.3429 20.0762 9.2 19.4 9.2ZM16 11.7C13.6 11.7 11.7 13.6 11.7 16C11.7 18.4 13.6 20.3 16 20.3C18.4 20.3 20.3 18.4 20.3 16C20.3 13.6 18.4 11.7 16 11.7ZM21.4 11.6C21.4 12.1523 20.9523 12.6 20.4 12.6C19.8477 12.6 19.4 12.1523 19.4 11.6C19.4 11.0477 19.8477 10.6 20.4 10.6C20.9523 10.6 21.4 11.0477 21.4 11.6Z"
                    fill="#584E7E" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM12.6 7.7H19.4C20.3 7.8 20.9 7.9 21.4 8.1C22 8.4 22.4 8.6 22.9 9.1C23.4 9.6 23.7 10.1 23.9 10.6C24.1 11.1 24.3 11.7 24.3 12.6V19.4C24.2 20.3 24.1 20.9 23.9 21.4C23.6 22 23.4 22.4 22.9 22.9C22.4 23.4 21.9 23.7 21.4 23.9C20.9 24.1 20.3 24.3 19.4 24.3H12.6C11.7 24.2 11.1 24.1 10.6 23.9C10 23.6 9.6 23.4 9.1 22.9C8.6 22.4 8.3 21.9 8.1 21.4C7.9 20.9 7.7 20.3 7.7 19.4V12.6C7.8 11.7 7.9 11.1 8.1 10.6C8.4 10 8.6 9.6 9.1 9.1C9.6 8.6 10.1 8.3 10.6 8.1C11.1 7.9 11.7 7.7 12.6 7.7Z"
                    fill="#584E7E" />
                </g>
              </g>
            </svg>
            <p class="text--primary" style="margin-left: 10px">Instagram</p>
          </a>
        </div>
      </div>
      <div class="section-contact-img" style="flex: 1;">
        <img :src="imgContact" />
      </div>
    </section>

  </div>
</template>

<script>
import ScrollIndicator from '../components/svgs/ScrollIndicator.vue'
import PostArchive from '../components/PostArchive.vue'
import ParallaxJS from '../components/ParallaxJS.vue'

import img1 from '../assets/parallax1.jpg'
import img2 from '../assets/parallax2.jpg'
import img3 from '../assets/parallax3.jpg'
import img4 from '../assets/parallax4.jpg'
import imgContact from '../assets/contact-us.jpg'
import imgPontus from '../assets/Pontus.jpg'
import imgTina from '../assets/Tina.png'
import imgAppDisplay from '../assets/app-display.png'
import imgDownloadAppStore from '../assets/download-app-store.png'
import imgDownloadGooglePlay from '../assets/download-google-play.png'

export default {
  metaInfo: {
    title: 'Hem',
  },
  components: { 
    ScrollIndicator,
    PostArchive,
    ParallaxJS,
  },
  name: 'IndexPage',
  data() {
    return {
      showScrollIndicator: true,
      parallaxOffset: 0,
      img1,
      img2,
      img3,
      img4,
      imgContact,
      imgPontus,
      imgTina,
      imgAppDisplay,
      imgDownloadAppStore,
      imgDownloadGooglePlay,
    }
  },
  methods: {
    updateScroll() {
      this.showScrollIndicator = window.scrollY < 50
      this.parallaxOffset = window.scrollY * 0.3
    },
    scrollToRef(ref) {
      let el
      if(ref == '#section-app') {
        el = this.$refs.sectionApp
      } else if(ref == '#section-about') {
        el = this.$refs.sectionAbout
      } else if(ref == '#home') {
        el = this.$refs.home
      }

      el.scrollIntoView({ behavior: 'smooth', block: 'start' })
    },
  },
  computed: {
    postsToDisplay() {
      const all = this.$store.state.queryResults
      return all.slice(0, 3)
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
    window.scrollTo(0, 0)
   
    if(window.location.hash) {
      this.scrollToRef(window.location.hash)
    }

    window.addEventListener('hashchange', () => {
      if(window.location.hash) {
        this.scrollToRef(window.location.hash)
      }
    })

  },
  unmounted() {
    window.removeEventListener('scroll', this.updateScroll)
  }
}
</script>

<style scoped>
.background-container {
  position: relative;
  width: 100%;
  height: 70vh;
  max-height: 100%;
  overflow: hidden;
}

.home-background {
  /* position: relative; */
  /* width: 100%; */

  height: 100vh;
  max-height: 100%;
  width: 100%;
  padding: 0;
  display: block;
  margin: 0 auto;
  object-fit: cover;
  object-position: top;
}



.card {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 400px;
  width: 90vw;
  font-family: "Open Sans", sans-serif;
  font-size: max(0.9vw, 14px);
  padding: 1em;
  box-sizing: border-box;

  transform: translate(-50%, 0);
  left: 50%;
  bottom: 30%;
  background-color: #6d629c79;

  backdrop-filter: blur(10px);

  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.scroll-indicator {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);

  transition: opacity 500ms ease;
  opacity: 1;
}

.scroll-indicator.scroll-indicator-hidden {
  opacity: 0;
}

.subtile {
  display: flex;
  width: 100%;
}

h3 {
  color: white;
  display: flex;
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
  font-weight: lighter;
  font-size: 18px;
}

.section {
  margin: 50px 0;
  margin-left: var(--horizontal-margin);
  margin-right: var(--horizontal-margin);
}

.section-app {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.section-app div.section-app-text {
  flex: 3;
  margin-right: 70px;
}
.section-app div.app-section-download {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.section-app div.app-section-download a {
  margin: 0px 30px;
  margin-top: 60px;
  padding: 0;
  display: block;
  text-decoration: none;

}
.section-app div.app-section-download a img {
  min-width: none;
  width: auto;
  height: 50px;
}
.section-app div.section-app-img {
  flex: 2;
}

.section-app > div > img {
  width: 100%;
  min-width: none;
}

.top-posts {
  display: flex;
  flex-direction: row;
  /* margin-left: var(--horizontal-margin);
  margin-right: var(--horizontal-margin); */
  grid-column-gap: 50px;
  /* margin-top: 10px; */
  margin-bottom: 50px;
}

.top-posts > div {
  flex: 1;
}

.top-posts-header {
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-left: var(--horizontal-margin);
  margin-right: var(--horizontal-margin);
  margin-top: 50px;
  margin-bottom: 20px;
}

.section-about {
  display: flex;
  flex-direction: column;
}

.section-about-img img {
  /* width: 20vw; */
  width: 100%;
  height: 100%;
  min-width: none;
  object-fit: cover;

  border-radius: 10px;
}

.section-about-paragraph {
  display: flex;
  flex-direction: row;
  column-gap: 120px;
  row-gap: 30px;
}

.section-about-paragraph-bottom {
  flex-direction: row-reverse;
}

.section-about-paragraph-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 2;
  margin: 60px 0;
}

.section-contact {
  display: flex;
  flex-direction: row;
}

.section-contact-text {
  flex: 1;
  margin-right: 70px;
}

.section-contact-img img {
  width: 100%;
  min-width: none;
  object-fit: cover;

  border-radius: 10px;
}

.section-contact-socials {
  display: flex;
  flex-direction: row;

  margin-top: 20px;
  column-gap: 50px;
}
.section-contact-socials a {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 1200px) { 
  .top-posts div:last-child {
    display: none;
  }

  .section-app div.section-app-text {
    margin-right: 30px;
  }

  .section-about-paragraph-text {
    margin: 0;
  }

  .section-about-paragraph {
    column-gap: 30px;
  }
}

@media screen and (max-width: 1000px) { 
  .section-app {
    flex-direction: column-reverse;
  }

  .section-app div.section-app-img {
    width: 70%;
    margin-bottom: 60px;
  }

  .section-app div.section-app-text {
    margin-right: 0px;
  }

  .section-contact {
    flex-direction: column-reverse;
    row-gap: 30px;
  }

  .section-contact-text {
    margin-right: 0px;
  }

  .section-contact-socials {
    justify-content: center;
  }
}

@media screen and (max-width: 800px) {
  .card {
    padding: 5vw 5vw;
  }

  .top-posts {
    flex-direction: column;
    grid-row-gap: 50px;
  }
  
  .section-app {
    flex-direction: column-reverse;
  }

  .section-app div.section-app-img {
    width: 100%;
    margin-bottom: 20px;
  }

  .section-app div.app-section-download {
    flex-direction: column;
  }

  .section-app div.app-section-download a {
    margin: 0;
    margin-top: 30px;
  }

  .section-about-paragraph {
    flex-direction: column;
  }

  .section-contact-socials {
    flex-direction: column;
    row-gap: 10px;
  }

}

</style>

