<template>
  <div id="app">
    <div class="content">
      <router-view/>
    </div>
    <Footer v-if="!hideNavigation" />
    <Navbar v-if="!hideNavigation" />
  </div>
</template>

<script>
import Footer from './components/Footer.vue'
import HelloWorld from './components/HelloWorld.vue'
import Navbar from './components/Navbar.vue'
import Home from './views/Home.vue'

export default {
  name: 'app',
  components: {
    HelloWorld,
    Home,
    Navbar,
    Footer
  },
  computed: {
    hideNavigation() {
      return this.$route.meta.hideNav
      // return this.$route.matched.some(route => route.path.includes('/admin'))
    }
  },
  methods: {
    
  },
  
  metaInfo: {
    titleTemplate: '%s - Blomstrande Liv',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: 'Hemsida för Blomstrande Liv' }, //TODO: Add description
      { name: 'keywords', content: 'blomstrande, liv, blomstrande liv, blomstra' },
      { name: 'author', content: 'Pontus och Tina Runeke' },
      { name: 'robots', content: 'index, follow' },
      { name: 'googlebot', content: 'index, follow' },

    ],
  }
}
</script>

<style>
@import './styles/variables.css';
@import './styles/main.css';
@import './styles/admin-variables.css';

#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

</style>
